import {
	RESTRICTIONS_BUTTON_SEGMENT_KEY,
	createPageSegmentLoadMetric,
	createInteractionMetric,
	createCustomMetric,
	RESTRICTIONS_DIALOG_INTERACTION,
	SHARE_FROM_RESTRICTIONS_KEY,
} from '@confluence/browser-metrics';

export const RESTRICTIONS_BUTTON_METRIC = createPageSegmentLoadMetric({
	key: RESTRICTIONS_BUTTON_SEGMENT_KEY,
	timings: [
		{
			key: 'fmp',
			endMark: 'fmp',
		},
		{
			key: 'tti',
			endMark: 'tti',
		},
	],
});

export const RESTRICTIONS_DIALOG_METRIC = createInteractionMetric({
	key: RESTRICTIONS_DIALOG_INTERACTION,
});

export const SHARE_FROM_RESTRICTIONS_TIME_METRIC = createCustomMetric({
	key: SHARE_FROM_RESTRICTIONS_KEY,
});
