import React from 'react';

import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';

import { RestrictionsButtonPlaceholder } from './RestrictionsButtonPlaceholder';

const checkForSSRRestrictionsButtonClicked = () => {
	return Boolean(window.__SSR_EVENTS_CAPTURE__?.restrictionsButton);
};

export const RestrictionsButtonLoading = () => {
	return checkForSSRRestrictionsButtonClicked() ? (
		<SSRActionLoadingSpinner
			spinnerId="restrictions-loading-spinner"
			actionType="restrictionsButton"
		/>
	) : (
		<RestrictionsButtonPlaceholder />
	);
};
