import React, { memo, useContext, useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import type { UseAnalyticsEventsHook } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';

import {
	RESTRICTIONS_DIALOG_EXPERIENCE,
	RESTRICTIONS_DIALOG_LOAD_EXPERIENCE,
	RESTRICTIONS_DIALOG_APPLY_EXPERIENCE,
	ExperienceTimeout,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import {
	ChangeEditionInlineDialogLoader,
	ChangeEditionInlineDialogPaintLoader,
	ConfluenceEdition,
} from '@confluence/change-edition';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { GrantAccessDialogLoader } from '@confluence/grant-access-dialog';
import type { RoutesContextType } from '@confluence/route-manager';
import { RoutesContext, getSingleParam } from '@confluence/route-manager';
import {
	PageRestrictionsContextConsumer,
	useContentId,
} from '@confluence/page-restrictions-context';
import type { RestrictionsType } from '@confluence/page-restrictions-context';
import { useSessionData } from '@confluence/session-data';
import { useContentType } from '@confluence/page-context';
import { RestrictionsDialogStateContainer } from '@confluence/restrictions-dialog-state-container';

import { RESTRICTIONS_BUTTON_METRIC, RESTRICTIONS_DIALOG_METRIC } from '../perf.config';
import {
	RestrictionsButtonLoaderAfterPaint,
	RestrictionsButtonLoaderPaint,
} from '../RestrictionsButton';
import { RestrictionsButtonPlaceholder } from '../RestrictionsButton/RestrictionsButtonPlaceholder';
import { RestrictionsButtonLoading } from '../RestrictionsButton/RestrictionsButtonLoading';
import type { RestrictionsDialogTriggerProps } from '../types';

const buttonContainerStyles = xcss({
	display: 'inline-block',
	verticalAlign: 'top',
});

const INLINE_DIALOG_PLACEMENT = 'bottom-end';

export const RestrictionsDialogTrigger = memo((props: RestrictionsDialogTriggerProps) => {
	const contentId = useContentId(props.contentId) || '';
	const { edition } = useSessionData();
	const [state, setState] = useState({
		grantAccessClicked: false,
		hasAutoOpenedRestrictionDialog: false,
		showFreeRestrictionDialog: false,
	});
	const contentSubType = props.contentSubType;

	const useAnalyticsEventsHook = useAnalyticsEvents();
	const [contentType] = useContentType();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const makeHandleOpen = (dialogStateContainer: RestrictionsDialogStateContainer) => () =>
		startRestrictionsDialogExperience({
			...useAnalyticsEventsHook,
			actionSubjectId: 'restrictionsDialogButton',
			dialogStateContainer,
			edition,
			experienceTracker,
			contentType,
			contentSubType,
			contentId,
		});

	const getParentData = (restrictions?: RestrictionsType) => {
		const ancestors = restrictions?.ancestors || [];
		for (let i = ancestors.length - 1; i >= 0; i--) {
			if (ancestors[i].hasRestrictions) {
				return ancestors[i]; // content
			}
		}
	};

	const handleGrantAccessActionClicked = () => {
		setState((state) => ({ ...state, grantAccessClicked: true }));
		if (window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton) {
			delete window.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
		}
	};

	const handleFreeEditionPadlockClicked = () => {
		setState((state) => ({ ...state, showFreeRestrictionDialog: true }));
		if (window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton) {
			delete window.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
		}
	};

	const onFreeDialogClosed = () => {
		setState((state) => ({ ...state, showFreeRestrictionDialog: false }));
		if (window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton) {
			delete window.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
		}
	};

	const renderFreeEditionDialog = (
		dialogStateContainer: RestrictionsDialogStateContainer,
		shouldShowDialog: boolean,
	) => {
		const { isDisabled, triggeredFrom } = props;

		// already free edition
		const shouldSSRViewPageFreeEditionButton = triggeredFrom === 'fromView';
		return (
			<PageRestrictionsContextConsumer>
				{({ loading, error, restrictions }) => {
					if ((!restrictions && loading) || error) {
						return <RestrictionsButtonPlaceholder />;
					}

					const contentHasRestrictions = Boolean(
						restrictions?.hasRestrictions || restrictions?.hasInheritedRestrictions,
					);
					const contentHasInheritedRestrictionsOnly = Boolean(
						restrictions?.hasInheritedRestrictions && !restrictions?.hasRestrictions,
					);

					const immediateParentPageIndex = (restrictions?.ancestors.length || 0) - 1;
					const isChildPage = immediateParentPageIndex > 0;

					// Note: content with restrictions is excluded from the pageRestrictionsExperiment
					if (contentHasRestrictions) {
						if (isChildPage && contentHasInheritedRestrictionsOnly) {
							const parentData = getParentData(restrictions);

							return shouldSSRViewPageFreeEditionButton ? (
								<ChangeEditionInlineDialogPaintLoader
									isOpen={state.showFreeRestrictionDialog}
									onClose={onFreeDialogClosed}
									parentData={parentData}
									isChildPage={isChildPage}
									placement={INLINE_DIALOG_PLACEMENT}
								>
									<RestrictionsButtonLoaderPaint
										contentId={contentId}
										isDisabled={isDisabled}
										shouldHideSpinner={state.showFreeRestrictionDialog}
										onClick={handleFreeEditionPadlockClicked}
										restrictionsButtonSSRRendered
										restrictionsButtonSSRLocation="free inherited"
										triggeredFrom={triggeredFrom}
									/>
								</ChangeEditionInlineDialogPaintLoader>
							) : (
								<ChangeEditionInlineDialogLoader
									isOpen={state.showFreeRestrictionDialog}
									onClose={onFreeDialogClosed}
									parentData={parentData}
									isChildPage={isChildPage}
									placement={INLINE_DIALOG_PLACEMENT}
								>
									<RestrictionsButtonLoaderAfterPaint
										contentId={contentId}
										isDisabled={isDisabled}
										shouldHideSpinner
										onClick={handleFreeEditionPadlockClicked}
										restrictionsButtonSSRLocation="free inherited"
										triggeredFrom={triggeredFrom}
									/>
								</ChangeEditionInlineDialogLoader>
							);
						}

						if (shouldShowDialog && !state.hasAutoOpenedRestrictionDialog) {
							makeHandleOpen(dialogStateContainer)();
							setState((state) => ({
								...state,
								hasAutoOpenedRestrictionDialog: true,
							}));
						}
						// grandfathered content
						return shouldSSRViewPageFreeEditionButton ? (
							<RestrictionsButtonLoaderPaint
								contentId={contentId}
								isDisabled={isDisabled}
								shouldHideSpinner={dialogStateContainer.getHideRestrictionsButtonSpinner()}
								resetDialogState={() => dialogStateContainer.initState()}
								onClick={makeHandleOpen(dialogStateContainer)}
								restrictionsButtonSSRRendered
								restrictionsButtonSSRLocation="free grandfather"
								triggeredFrom={triggeredFrom}
							/>
						) : (
							<RestrictionsButtonLoaderAfterPaint
								contentId={contentId}
								isDisabled={isDisabled}
								shouldHideSpinner
								onClick={makeHandleOpen(dialogStateContainer)}
								restrictionsButtonSSRLocation="free grandfather"
								triggeredFrom={triggeredFrom}
							/>
						);
					}
					return shouldSSRViewPageFreeEditionButton ? (
						<ChangeEditionInlineDialogPaintLoader
							isOpen={state.showFreeRestrictionDialog}
							onClose={onFreeDialogClosed}
							placement={INLINE_DIALOG_PLACEMENT}
						>
							<RestrictionsButtonLoaderPaint
								contentId={contentId}
								shouldHideSpinner={state.showFreeRestrictionDialog}
								isDisabled={isDisabled}
								onClick={handleFreeEditionPadlockClicked}
								restrictionsButtonSSRRendered
								restrictionsButtonSSRLocation="free"
								triggeredFrom={triggeredFrom}
							/>
						</ChangeEditionInlineDialogPaintLoader>
					) : (
						<ChangeEditionInlineDialogLoader
							isOpen={state.showFreeRestrictionDialog}
							onClose={onFreeDialogClosed}
							placement={INLINE_DIALOG_PLACEMENT}
						>
							<RestrictionsButtonLoaderAfterPaint
								contentId={contentId}
								shouldHideSpinner
								isDisabled={isDisabled}
								onClick={handleFreeEditionPadlockClicked}
								restrictionsButtonSSRLocation="free"
								triggeredFrom={triggeredFrom}
							/>
						</ChangeEditionInlineDialogLoader>
					);
				}}
			</PageRestrictionsContextConsumer>
		);
	};

	//  accessType=view&grantAccess=true&username=rwu&userFullName=Robin+Wu
	const renderGrantAccessDialogAndButton = (
		getQueryParams: RoutesContextType['getQueryParams'],
		dialogStateContainer: RestrictionsDialogStateContainer,
		shouldShowDialog: boolean,
	) => {
		const { isDisabled, triggeredFrom } = props;

		const queryParams = getQueryParams();

		const shouldSSRGrantAccessRestrictions = triggeredFrom === 'fromView';

		if (shouldShowDialog && !state.grantAccessClicked) {
			if (process.env.REACT_SSR && !shouldSSRGrantAccessRestrictions) {
				return <RestrictionsButtonPlaceholder />;
			}
			const accountId = getSingleParam(queryParams, 'accountId');
			// TODO(brizvash): non-gdpr params, remove when GDPR is on
			// "username" in the URL is actually the accountId in legacy circumstances
			// which we will use to fetch username from backend
			const legacyUsername = getSingleParam(queryParams, 'username') || '';
			// render grant access requested user
			// preloading the queries means that isOpen wil always be true in SSR.
			// here, we set isOpen to false during SSR so the modal doesn't open on SSR (since
			// there is no handling for the inner modal clicks)
			return (
				<GrantAccessDialogLoader
					isOpen={!process.env.REACT_SSR}
					contentId={contentId}
					onActionClick={handleGrantAccessActionClicked}
					accessType={getSingleParam(queryParams, 'accessType') === 'edit' ? 'edit' : 'view'}
					// When accountId is not present, username will have the Id
					accountId={accountId || legacyUsername}
					shouldSSRGrantAccessRestrictions={shouldSSRGrantAccessRestrictions}
					buttonPlaceholder={<RestrictionsButtonPlaceholder />}
				>
					{shouldSSRGrantAccessRestrictions ? (
						<RestrictionsButtonLoaderPaint
							contentId={contentId}
							isDisabled={isDisabled}
							shouldHideSpinner={dialogStateContainer.getHideRestrictionsButtonSpinner()}
							resetDialogState={() => dialogStateContainer.initState()}
							onClick={makeHandleOpen(dialogStateContainer)}
							restrictionsButtonSSRRendered
							restrictionsButtonSSRLocation="default grant access"
							triggeredFrom={triggeredFrom}
						/>
					) : (
						<RestrictionsButtonLoaderAfterPaint
							contentId={contentId}
							isDisabled={isDisabled}
							shouldHideSpinner
							onClick={makeHandleOpen(dialogStateContainer)}
							restrictionsButtonSSRLocation="default grant access"
							triggeredFrom={triggeredFrom}
						/>
					)}
				</GrantAccessDialogLoader>
			);
		}

		// Default restrictions dialog
		if (triggeredFrom && triggeredFrom === 'fromView') {
			return (
				<RestrictionsButtonLoaderPaint
					contentId={contentId}
					isDisabled={isDisabled}
					shouldHideSpinner={dialogStateContainer.getHideRestrictionsButtonSpinner()}
					onClick={makeHandleOpen(dialogStateContainer)}
					resetDialogState={() => dialogStateContainer.initState()}
					restrictionsButtonSSRRendered
					restrictionsButtonSSRLocation="default"
					triggeredFrom={triggeredFrom}
				/>
			);
		}
		return (
			<RestrictionsButtonLoaderAfterPaint
				contentId={contentId}
				isDisabled={isDisabled}
				shouldHideSpinner
				onClick={makeHandleOpen(dialogStateContainer)}
				restrictionsButtonSSRLocation="default"
				triggeredFrom={triggeredFrom}
			/>
		);
	};

	return (
		<Subscribe to={[RestrictionsDialogStateContainer]}>
			{(dialogStateContainer: RestrictionsDialogStateContainer) => (
				<Box xcss={buttonContainerStyles}>
					<PageSegmentLoadStart
						isCustomStart
						key={`start-${contentId}`}
						metric={RESTRICTIONS_BUTTON_METRIC}
					/>

					{edition ? (
						<RoutesContext.Consumer>
							{({ getQueryParams }) => {
								const {
									accountId,
									grantAccess,
									// TODO(brizvash): non-gdpr params, remove when GDPR is on
									username,
								} = getQueryParams();

								const shouldShowGrantAccessDialog = Boolean((grantAccess && accountId) || username);

								return edition === ConfluenceEdition.FREE
									? renderFreeEditionDialog(dialogStateContainer, shouldShowGrantAccessDialog)
									: renderGrantAccessDialogAndButton(
											getQueryParams,
											dialogStateContainer,
											shouldShowGrantAccessDialog,
										);
							}}
						</RoutesContext.Consumer>
					) : (
						<RestrictionsButtonLoading />
					)}
				</Box>
			)}
		</Subscribe>
	);
});

RestrictionsDialogTrigger.displayName = 'RestrictionsDialogTrigger';

export function startRestrictionsDialogExperience({
	actionSubjectId,
	createAnalyticsEvent,
	dialogStateContainer,
	edition,
	experienceTracker,
	triggeredFrom,
	contentType,
	contentSubType,
	contentId,
}: {
	actionSubjectId: string;
	dialogStateContainer: RestrictionsDialogStateContainer;
	edition: ConfluenceEdition | null;
	experienceTracker: ExperienceTrackerAPI;
	triggeredFrom?: string;
	contentType?: string;
	contentSubType?: string | null;
	contentId: string;
} & UseAnalyticsEventsHook) {
	createAnalyticsEvent({
		type: 'sendUIEvent',
		data: {
			source: 'viewPageScreen',
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId,
			attributes: {
				source: 'v2',
				triggeredFrom,
				edition,
				contentType,
				isLivePage: contentSubType === 'live',
			},
		},
	}).fire();
	experienceTracker.start({
		name: RESTRICTIONS_DIALOG_EXPERIENCE,
		attributes: {
			contentType,
		},
		collect(events, experience) {
			// Fail/Abort RESTRICTIONS_DIALOG as soon as _LOAD or _APPLY fails or aborts:
			experience.stopOn(
				events.find(
					({ action, name }) =>
						(action === 'taskFail' || action === 'taskAbort') &&
						(name === RESTRICTIONS_DIALOG_LOAD_EXPERIENCE ||
							name === RESTRICTIONS_DIALOG_APPLY_EXPERIENCE),
				),
			);
		},
	});
	experienceTracker.start({
		name: RESTRICTIONS_DIALOG_LOAD_EXPERIENCE,
		timeout: ExperienceTimeout.DEFAULT,
	});

	//
	// Open RestrictionsDialog:
	//

	void dialogStateContainer.setContentId(contentId);
	void dialogStateContainer.setIsOpen(true, triggeredFrom);

	RESTRICTIONS_DIALOG_METRIC.start();
}
