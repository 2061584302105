import { LoadablePaint, LoadableAfterPaint } from '@confluence/loadable';

import { RestrictionsButtonPlaceholder } from './RestrictionsButtonPlaceholder';
import { RestrictionsButtonLoading } from './RestrictionsButtonLoading';

export const RestrictionsButtonLoaderPaint = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-RestrictionsButton" */ './RestrictionsButton'))
			.RestrictionsButton,
	loading: RestrictionsButtonLoading,
});

export const RestrictionsButtonLoaderAfterPaint = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-RestrictionsButton" */ './RestrictionsButton'))
			.RestrictionsButton,
	loading: RestrictionsButtonPlaceholder,
});
